<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333.72 196.82">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <rect class="cls-2" x="3.5" y="3.5" width="282.41" height="173.78" />
        <g>
          <path
            class="cls-3"
            d="m70.52,77.37c-.07.09-.18.13-.3.15-.1.01-.22-.03-.33-.15l-2.91-2.9c-1.73.87-3.67,1.3-5.81,1.3-2.51,0-4.69-.57-6.55-1.73-1.87-1.15-3.16-2.88-3.9-5.21-.16-.51-.3-1-.4-1.48-.09-.48-.16-1.01-.22-1.6-.06-.6-.1-1.27-.13-2.03-.01-.76-.03-1.67-.03-2.73s.01-1.98.03-2.75c.03-.75.07-1.43.13-2.01.06-.6.13-1.12.22-1.6.1-.48.24-.97.4-1.48.73-2.3,2.03-4.03,3.9-5.19,1.87-1.16,4.04-1.76,6.55-1.76s4.7.58,6.55,1.73c1.84,1.15,3.13,2.9,3.9,5.22.16.51.3,1,.39,1.48.1.48.18,1,.24,1.6.06.58.09,1.27.12,2.01.03.76.04,1.67.04,2.75s-.01,1.97-.04,2.73-.06,1.43-.12,2.03c-.06.58-.13,1.12-.24,1.6-.09.48-.22.97-.39,1.48-.12.3-.22.6-.34.88s-.24.55-.37.84l2.98,2.96c.19.19.19.39,0,.58l-3.37,3.28Zm-9.34-7.07c.58,0,1.09-.04,1.51-.16l-1.81-1.81c-.19-.19-.19-.39,0-.58l3.37-3.19c.22-.19.43-.19.63,0l1.13,1.13c.06-.46.1-1.07.13-1.82.03-.73.04-1.7.04-2.87,0-.93-.01-1.72-.03-2.39s-.04-1.25-.07-1.73c-.04-.46-.1-.87-.15-1.19-.06-.31-.13-.6-.21-.85-.31-.99-.85-1.75-1.63-2.31-.78-.55-1.75-.84-2.93-.84s-2.15.28-2.93.84c-.78.57-1.31,1.33-1.63,2.31-.07.25-.15.54-.21.85-.06.33-.1.73-.15,1.19-.04.48-.07,1.06-.07,1.73-.01.67-.03,1.46-.03,2.39s.01,1.72.03,2.39c0,.67.03,1.24.07,1.72s.09.88.15,1.19c.06.33.13.61.21.87.31.97.85,1.75,1.63,2.3.78.57,1.75.84,2.93.84Z"
          />
          <path
            class="cls-3"
            d="m87.86,75.77c-1.6,0-3.07-.24-4.42-.72s-2.51-1.18-3.48-2.1-1.72-2.07-2.27-3.45c-.55-1.37-.82-2.96-.82-4.72v-17.69c0-.28.13-.42.42-.42h5.34c.28,0,.43.13.43.42v17.85c0,1.72.43,3.03,1.3,3.96s2.03,1.39,3.49,1.39,2.58-.46,3.46-1.39c.87-.93,1.3-2.24,1.3-3.96v-17.85c0-.28.13-.42.42-.42h5.36c.27,0,.42.13.42.42v17.69c0,1.76-.28,3.34-.82,4.72-.55,1.37-1.31,2.52-2.28,3.45-.96.93-2.12,1.63-3.45,2.1s-2.81.72-4.4.72Z"
          />
          <path
            class="cls-3"
            d="m111.76,47.1c.07-.28.27-.42.54-.42h5.31c.28,0,.46.13.55.42l9.67,27.79c.09.28-.01.42-.28.42h-5.48c-.28,0-.46-.13-.55-.42l-1.64-5.01h-10.01l-1.6,5.01c-.09.28-.27.42-.55.42h-5.52c-.28,0-.37-.13-.3-.42l9.87-27.79Zm6.43,17.51l-3.24-10.01h-.12l-3.25,10.01h6.61Z"
          />
          <path
            class="cls-3"
            d="m131.02,47.1c0-.28.15-.42.43-.42h5.34c.28,0,.42.13.42.42v22.48c0,.18.09.25.25.25h12.63c.28,0,.43.15.43.42v4.64c0,.28-.15.42-.43.42h-18.64c-.28,0-.43-.13-.43-.42v-27.79Z"
          />
          <path
            class="cls-3"
            d="m154.05,47.1c0-.28.15-.42.43-.42h5.34c.28,0,.42.13.42.42v27.79c0,.28-.13.42-.42.42h-5.34c-.28,0-.43-.13-.43-.42v-27.79Z"
          />
          <path
            class="cls-3"
            d="m171.92,75.31c-.28,0-.43-.13-.43-.42v-22.45c0-.16-.07-.25-.25-.25h-7.24c-.28,0-.42-.13-.42-.42v-4.67c0-.28.13-.42.42-.42h21.18c.28,0,.43.13.43.42v4.67c0,.28-.15.42-.43.42h-7.24c-.16,0-.25.09-.25.25v22.45c0,.28-.13.42-.42.42h-5.34Z"
          />
          <path
            class="cls-3"
            d="m195.45,75.31c-.28,0-.43-.13-.43-.42v-11l-8.46-16.79c-.1-.28-.01-.42.3-.42h5.48c.31,0,.52.13.63.42l5.18,10.43h.13l5.18-10.43c.1-.28.31-.42.63-.42h5.31c.13,0,.24.03.28.12.06.09.06.18,0,.3l-8.46,16.79v11c0,.28-.13.42-.42.42h-5.34Z"
          />
          <path
            class="cls-3"
            d="m57.01,121.62c-.28,0-.43-.13-.43-.42v-22.45c0-.16-.07-.25-.25-.25h-7.24c-.28,0-.42-.13-.42-.42v-4.67c0-.28.13-.42.42-.42h21.18c.28,0,.43.13.43.42v4.67c0,.28-.15.42-.43.42h-7.24c-.16,0-.25.09-.25.25v22.45c0,.28-.13.42-.42.42h-5.34Z"
          />
          <path
            class="cls-3"
            d="m74.01,93.41c0-.28.15-.42.43-.42h18.36c.27,0,.42.13.42.42v4.51c0,.27-.15.42-.42.42h-12.34c-.16,0-.25.07-.25.25v5.69c0,.16.09.25.25.25h10.27c.28,0,.43.13.43.42v4.46c0,.28-.15.42-.43.42h-10.27c-.16,0-.25.09-.25.25v5.94c0,.16.09.25.25.25h12.34c.27,0,.42.13.42.42v4.51c0,.28-.15.42-.42.42h-18.36c-.28,0-.43-.13-.43-.42v-27.79Z"
          />
          <path
            class="cls-3"
            d="m107.32,122.08c-1.12,0-2.22-.1-3.3-.31-1.07-.21-2.1-.49-3.06-.85-.96-.34-1.82-.76-2.63-1.24-.79-.48-1.48-.99-2.04-1.51-.22-.22-.24-.46-.04-.72l3.21-3.67c.16-.22.37-.24.63-.04.96.76,2.06,1.45,3.33,2.04,1.27.61,2.66.91,4.16.91s2.69-.31,3.48-.96c.81-.63,1.21-1.45,1.21-2.46,0-.84-.31-1.52-.94-2.06-.61-.54-1.78-.91-3.49-1.13l-1.51-.21c-2.93-.39-5.16-1.28-6.72-2.67-1.57-1.39-2.34-3.37-2.34-5.93,0-1.31.24-2.51.72-3.6.48-1.07,1.16-2,2.04-2.76.88-.75,1.96-1.34,3.19-1.76,1.25-.42,2.66-.64,4.19-.64,1.91,0,3.7.27,5.39.81,1.69.54,3.13,1.27,4.34,2.19.25.16.28.37.07.63l-2.57,3.84c-.16.22-.36.27-.58.12-1.09-.73-2.18-1.27-3.27-1.64-1.07-.36-2.21-.54-3.39-.54-1.27,0-2.21.28-2.87.88-.64.58-.97,1.33-.97,2.22,0,.82.33,1.49,1,2.03.66.54,1.81.91,3.46,1.13l1.52.21c2.97.39,5.22,1.28,6.73,2.67,1.52,1.39,2.28,3.39,2.28,6,0,1.3-.24,2.49-.72,3.6-.48,1.12-1.19,2.07-2.13,2.87-.94.81-2.1,1.43-3.51,1.88-1.4.45-3.03.67-4.9.67Z"
          />
          <path
            class="cls-3"
            d="m128.93,121.62c-.28,0-.43-.13-.43-.42v-22.45c0-.16-.07-.25-.25-.25h-7.24c-.28,0-.42-.13-.42-.42v-4.67c0-.28.13-.42.42-.42h21.18c.28,0,.43.13.43.42v4.67c0,.28-.15.42-.43.42h-7.24c-.16,0-.25.09-.25.25v22.45c0,.28-.13.42-.42.42h-5.34Z"
          />
          <path
            class="cls-3"
            d="m145.93,93.41c0-.28.15-.42.43-.42h18.36c.27,0,.42.13.42.42v4.51c0,.27-.15.42-.42.42h-12.34c-.16,0-.25.07-.25.25v5.69c0,.16.09.25.25.25h10.27c.28,0,.43.13.43.42v4.46c0,.28-.15.42-.43.42h-10.27c-.16,0-.25.09-.25.25v5.94c0,.16.09.25.25.25h12.34c.27,0,.42.13.42.42v4.51c0,.28-.15.42-.42.42h-18.36c-.28,0-.43-.13-.43-.42v-27.79Z"
          />
          <path
            class="cls-3"
            d="m169.59,93.41c0-.28.15-.42.43-.42h10.64c2.54,0,4.64.52,6.33,1.6,1.67,1.06,2.87,2.67,3.57,4.84.12.4.22.81.33,1.24.09.45.18.96.24,1.54.07.6.12,1.3.15,2.1.03.82.04,1.82.04,3s-.01,2.18-.04,2.98c-.03.82-.07,1.52-.15,2.1-.06.6-.15,1.1-.24,1.54-.1.43-.21.85-.33,1.24-.7,2.16-1.9,3.78-3.57,4.85-1.69,1.06-3.79,1.6-6.33,1.6h-10.64c-.28,0-.43-.13-.43-.42v-27.79Zm6.19,22.48c0,.18.09.25.25.25h3.24c1.43,0,2.58-.22,3.46-.7.87-.46,1.48-1.28,1.85-2.46.07-.21.15-.43.21-.7s.1-.61.15-1.04c.04-.42.06-.94.07-1.58.01-.63.03-1.42.03-2.34s-.01-1.72-.03-2.33c-.01-.63-.03-1.15-.07-1.57s-.09-.78-.15-1.04c-.06-.25-.13-.51-.21-.73-.37-1.18-.99-2.01-1.85-2.48-.88-.46-2.03-.7-3.46-.7h-3.24c-.16,0-.25.09-.25.25v17.18Z"
          />
        </g>
        <path
          class="cls-1"
          d="m241.85,153.39c5.08-10.61,10.12-20.38,15.12-29.33,4.99-8.95,10.88-18.3,17.64-28.05,6.77-9.75,12.34-16.93,16.73-21.54,4.39-4.61,7.56-7.44,9.52-8.5,1.96-1.05,5.42-1.97,10.39-2.77,4.97-.8,9.08-1.2,12.34-1.2,1.99,0,3.59.62,4.8,1.87,1.21,1.24,1.82,2.75,1.82,4.51,0,1.44-.35,2.69-1.04,3.77-.69,1.07-2.25,2.72-4.68,4.93-10.94,10.11-22.25,24.38-33.93,42.8-11.68,18.42-21.01,36.31-27.97,53.65-2.71,6.68-4.66,11.08-5.84,13.18-1.19,2.1-2.85,3.73-4.97,4.89-2.13,1.16-6.06,1.74-11.81,1.74-4.2,0-7.1-.29-8.7-.87-1.6-.58-2.93-1.45-3.98-2.61-1.05-1.16-2.9-3.79-5.55-7.87-3.76-5.8-8.81-12.18-15.17-19.14-3.87-4.26-5.8-7.76-5.8-10.53,0-3.76,2.13-7.22,6.39-10.4,4.26-3.18,8.16-4.77,11.69-4.77s7.26,1.39,11.32,4.19c4.06,2.79,7.95,6.81,11.66,12.06"
        />
      </g>
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: #fff;
}

.cls-1,
.cls-2 {
  stroke: #267891;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.99px;
}

.cls-2 {
  fill: none;
}

.cls-3 {
  fill: #007992;
  stroke-width: 0px;
}
</style>
